<template>
  <section id="about" class="section">
    <div class="section__about-me">
      <MySubtitle subtitle="Sobre mim" />
      <p class="section__about-me-paragraph">{{ 'Sou estudante na área de tecnologia, graduado em Sistemas de Informação e pós-graduado em Cibersegurança e Governança de Dados. Atuei na área administrativa durante dois anos, com foco em equipe operacional e gestão de frota. Tenho mais de cinco anos de experiência na área de desenvolvimento. Trabalhei como estagiário em desenvolvimento web, focado em e-commerces e newsletters. Posteriormente, atuei como desenvolvedor frontend júnior. Atualmente, sou desenvolvedor frontend no Mercado Eletrônico e também trabalho como freelancer, desenvolvendo sites institucionais, landing pages e outras interfaces com as principais ferramentas de desenvolvimento web.' }}</p>
    </div>

    <div id="contact" class="section__box">
      <div
        v-for="item in contacts"
        :key="item.name"
        class="section__box-icons"
      >
        <CustomIcon
          :icon="item.icon"
          :name="item.name"
          class="animate-icon"
        />

        <a
          :href="item.link"
          target="_blank"
          class="link"
          rel="author"
        >
          <span class="name">{{ item.name }}</span>
          <span class="content">{{ item.text }}</span>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import CustomIcon from './ui/CustomIcon.vue'
import MySubtitle from './MySubtitle.vue'

export default {
  name: 'AboutMe',

  components: {
    CustomIcon,
    MySubtitle
  },

  computed: {
    contacts() {
      return [
        { icon: 'github', name: 'Github', text: 'lucasheverton', link: 'https://github.com/lucasheverton' },
        { icon: 'email', name: 'E-mail', text: 'lucas.7heverton@hotmail.com', link: 'mailto:lucas.7heverton@hotmail.com' },
        { icon: 'linkedin', name: 'Linkedin', text: '/lucasheverton', link: 'https://www.linkedin.com/in/lucasheverton/'},
        { icon: 'whatsapp', name: 'Contato', text: '(11) 99112 0429', link: 'https://api.whatsapp.com/send?phone=+5511991120429&text=Olá%20Lucas,%20te%20encontrei%20através%20do%20seu%20portfólio.' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.section {
  width: 100%;
  display: grid;
  justify-content: center;
  gap: 96px;

  @include mobile480 {
    gap: 64px;
  }

  &__about-me {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    gap: 32px;

    &-paragraph {
      color: $gray-10;
      font-size: 16px;
      font-weight: 600;
      width: 745px;
      text-align: justify;

      @include tablet768 {
        width: 425px;
      }

      @include mobile480 {
        width: 355px;
      }

      @include mobile375 {
        width: 280px;
      }
    }
  }

  &__box {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 48px;
    width: max-content;
    justify-self: center;

    @include laptop1024 {
      gap: 8px
    }

    @include tablet900 {
      grid-template-columns: repeat(2, 1fr);
      gap: 40px 16px;
    }

    @include mobile480 {
      grid-template-columns: 1fr;
    }

    @include mobile375 {
      gap: 24px 0;
    }

    &-icons {
      display: grid;
      gap: 16px;
      justify-items: center;

      .animate-icon {
        transition: all 0.4s ease-in;

          &:hover {
            transition: all 0.4s ease-in-out;
            transform: scale(1.1);
          }
      }

      .link {
        display: grid;
        justify-items: center;
        gap: 16px;
        color: $gray-10;
        font-size: 14px;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }

        .name {
          color: $ligth-20;
          font-size: 16px;
        }

        .content {
          @include mobile375 {
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>
