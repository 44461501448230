<template>
  <div class="default">
    <MyHeader />
    <MyPresentation />
    <AboutMe />
    <MyProjects />
    <MyServices />
    <MySkills />
    <BackUp />
  </div>
  <MyFooter />
</template>

<script>
import MyHeader from './components/nav/MyHeader.vue'
import MyPresentation from './components/MyPresentation.vue'
import AboutMe from './components/AboutMe.vue'
import MyProjects  from './components/MyProjects.vue'
import MyServices from './components/MyServices.vue'
import MySkills from './components/MySkills.vue'
import MyFooter from './components/nav/MyFooter.vue'
import BackUp from './components/BackToUp.vue'


export default {
  name: 'App',

  components: {
    MyHeader,
    MyPresentation,
    AboutMe,
    MyProjects,
    MyServices,
    MySkills,
    MyFooter,
    BackUp
  }
}
</script>

<style lang="scss">
* {
  font-family: "DM Sans", sans-serif;
  box-sizing: border-box;
  margin: 0;
}

html,
#portifolio {
  background-color: $dark-10;
  overflow-x: hidden;
  scroll-behavior: smooth;
  max-width: 1140px;
  margin: 0 auto;
}

::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

::-webkit-scrollbar-thumb {
    background: $brand-color;
    border-radius: 16px;
    -webkit-transition: opacity 1s;
    transition: opacity 1s;
}

::-webkit-scrollbar-track {
    display: none;
}

.default {
  width: 100%;
  margin: 32px auto 128px auto;
  display: grid;
  gap: 112px;

  @include laptop1024 {
    padding: 24px;
  }

  @include mobile480 {
    padding: 8px;
    gap: 64px;
  }
}
</style>
