<template>
  <section id="skills" class="section">
    <MySubtitle class="section-subtitle" subtitle="Minhas Skills" />

    <div class="glide">
      <div
        class="glide__track section"
        data-glide-el="track"
      >
        <div class="section__container-boxes glide__slides">
          <img
            v-for="techlogy in dataSkills"
            :key="techlogy.icon"
            :src="imagePathFormat(techlogy.icon)"
            :alt="techlogy.name"
            :title="techlogy.name"
            class="glide__slide"
            />
        </div>
      </div>
    </div>

  </section>
</template>

<script>
import MySubtitle from './MySubtitle.vue'
import Glide from '@glidejs/glide'

export default {
  name: 'MySkills',

  components: {
    MySubtitle
  },

  data() {
    return {
      dataSkills: [
        // { icon: 'angular', name: 'ANGULAR' },
        { icon: 'bootstrap', name: 'BOOTSTRAP' },
        { icon: 'css3', name: 'CSS3' },
        { icon: 'cypress', name: 'CYPRESS' },
        { icon: 'eslint', name: 'ESLINT' },
        { icon: 'git', name: 'GIT' },
        { icon: 'github', name: 'GITHUB' },
        { icon: 'html5', name: 'HTML5' },
        { icon: 'javascript', name: 'JAVASCRIPT' },
        { icon: 'linux', name: 'LINUX' },
        { icon: 'markdown', name: 'MARKDOWN' },
        { icon: 'sass', name: 'SASS' },
        // { icon: 'typescript', name: 'TYPESCRPIT' },
        { icon: 'vscode', name: 'VSCODE' },
        { icon: 'vue', name: 'VUE' }
      ]
    }
  },

  mounted() {
    new Glide('.glide', {
      type: 'carousel',
      perView: 6,
      autoplay: 1600,
      gap: 48,
      peek: {
        before: 96,
        after: 96
      },
      animationTimingFunc: 'ease-in-out'
    }).mount()
  },

  methods: {
    imagePathFormat(image) {
      return require(`../assets/img/skills/${image}.svg`)
    }
  }
}
</script>

<style lang="scss" scoped>
.section {
  width: 100%;
  display: grid;
  justify-items: center;

  &-subtitle {
    margin-bottom: 32px;
  }

  &__container {
    &-boxes {
      width: 144px;
      height: 144px;
      padding: 32px 0 !important;

      @include laptop1024 {
        height: 120px;
      }

      @include tablet600 {
        height: 104px;
        padding: 0 !important;

        img {
          width: 40px!important;
        }
      }

      @include mobile480 {
        img {
          width: 32px!important;
        }
      }
    }
  }
}

@import "../../node_modules/@glidejs/glide/src/assets/sass/glide.core";
@import "../../node_modules/@glidejs/glide/src/assets/sass/glide.theme";

.glide {
  max-width: 1140px;
  background-color: $dark-20;

  @include laptop1024 {
    max-width: 768px;
    }

  @include tablet900 {
    max-width: 680px;
  }

  @include tablet768 {
    max-width: 580px;
  }

  @include tablet600 {
    max-width: 460px;
  }

  @include mobile480 {
    max-width: 355px;
  }

  @include mobile375 {
    max-width: 302px;
  }
}
</style>
