<template>
  <section class="section">
    <div class="section__container">
      <h2 class="section__container-subtitle">
        {{ 'Olá, eu sou o' }}
        <span class="animate">{{ 'Lucas Heverton :)' }}</span>
      </h2>

      <p class="section__container-paragraph">
        {{ 'Desenvolvedor Front-End' }}
      </p>

      <div class="section__container-buttons">
        <a
          href="https://drive.usercontent.google.com/uc?id=1sg3uS6p-xVesL4WYW5dTJeLyc1vzcOPD&export=download"
          target="_blank"
          title="Baixar currículo"
          class="curriculum"
          download
        >
          {{ 'Download CV' }}
        </a>

        <a
          href="https://www.linkedin.com/in/lucasheverton/"
          target="_blank"
          rel="author"
        >
          {{ 'Entrar em contato' }}
        </a>
      </div>
    </div>

    <div class="section__display">
      <img
        class="section__display-image"
        src="../assets/img/main-image.gif"
        alt="Imagem de exibição"
        title="Imagem de exibição"
      />
    </div>
  </section>
</template>

<script>
export default {
  name: 'MyPresentation'
}
</script>

<style lang="scss" scoped>
 .section {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include tablet768 {
    flex-direction: column-reverse;
    gap: 40px;
  }

    &__container {
      display: flex;
      flex-direction: column;
      gap: 24px;

      &-subtitle {
        color: $ligth-20;
        font-size: 32px;
        font-weight: 600;
        word-break: break-word;
        width: 264px;

        .animate {
          display: block;
          white-space: nowrap;
          overflow: hidden;
          border-right: 2px solid;
          animation: typing 4s steps(34), blink-caret 1s step-end infinite;
        }
      }

      &-paragraph {
        color: $gray-10;
        font-size: 16px;
        font-weight: 600;
      }

      &-buttons {
        display:  flex;
        gap: 16px;

        @include mobile375 {
          gap: 8px;
        }

        a {
          width: 144px;
          height: 40px;
          appearance: none;
          text-align: center;
          outline: none;
          padding: 0;
          background-color: $dark-10;
          border: 1px solid $dark-30;
          color: $ligth-20;
          font-size: 14px;
          font-weight: 600;
          cursor: pointer;
          align-content: center;
          text-decoration: none;
        }

        .curriculum {
          background-color: $brand-color;
          color: $dark-10;
          border: none;
        }
      }
    }

    &__display {
      &-image {
        width: inherit;
        height: inherit;

        @include mobile375 {
          width: 280px;
          height: 280px;
        }
      }
    }
  }

  /* Animação de digitação */
@keyframes typing {
  from { width: 0; }
  to { width: 100%; }
}

/* Animação do cursor piscando */
@keyframes blink-caret {
  from, to { border-color: transparent; }
  50% { border-color: $brand-color; }
}
</style>
