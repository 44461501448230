<template>
    <h2 class="subtitle" :title="subtitle">
      {{ subtitle }}
    </h2>
</template>

<script>
export default {
  name: 'MySubtitle',

  props: {
    subtitle: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.subtitle {
  color: $ligth-20;
  font-size: 32px;
  font-weight: 600;
  cursor: default;
}
</style>
