<template>
  <div :class="hasBottomBorder">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'CustomBox',

  props: {
    border: {
      type: Boolean,
      default: false,
      required: false
    }
  },

  computed: {
    hasBottomBorder() {
      return this.border ? 'box border' : 'box'
    }
  }
}
</script>

<style lang="scss" scoped>
.box {
  width: 352px;
  height: 240px;
  padding: 16px 32px;
  background-color: $dark-20;

  @include mobile375 {
    width: 304px;
    height: 224px;
    padding: 8px 4px;
  }

  @include mobile320 {
    width: 296px;
  }
}

.border {
  transition: all 0.4s ease-in-out;

    &:hover {
      transition: all 0.4s ease-in;
      border-bottom: 4px solid $brand-color;
    }
}
</style>
