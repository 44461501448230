<template>
  <!-- eslint-disable-next-line -->
  <transition name="fade">
    <button v-if="showScrollButton">
      <a href="#portifolio">
        <img
        src="../assets/img/chevron-up.svg"
        alt="Voltar para cima"
        title="Voltar para cima"
        />
      </a>
    </button>
  </transition>
</template>

<script>
export default {
  name: 'BackUp',

  data() {
    return {
      showScrollButton: false,
    }
  },

  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },

  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  },

  methods: {
    handleScroll() {
      // Mostra o botão se o usuário rolou mais de 180px
      this.showScrollButton = window.scrollY > 180
    }
  }
}
</script>

<style lang="scss" scoped>
button {
  position: fixed;
  right: 24px;
  bottom: 40px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: $dark-30;
  border: 1px solid $dark-20;
  cursor: pointer;
  z-index: 999;

  @include mobile375 {
    width: 64px;
    height: 64px;
  }

  a {
    text-decoration: none;

    @include mobile375 {
      img {
        width: 24px;
        height: 24px;
      }
    }
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 1s ease;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
</style>
