<template>
  <section id="services" class="section">
    <MySubtitle class="section-subtitle" subtitle="Serviços" />

    <div class="section__boxes">
      <div v-for="item in dataProjects" :key="item">
        <CustomBox border>
          <div class="section__boxes-container">
            <img
              :src="imagePathFormat(item.image)"
              :alt="item.title"
              :title="item.title"
              class="section__boxes-container-image"
            >
            <h3 class="section__boxes-container-title">{{ item.title }}</h3>
          </div>
        </CustomBox>
      </div>
    </div>
  </section>
</template>

<script>
import CustomBox from './ui/CustomBox.vue'
import MySubtitle from './MySubtitle.vue'

export default {
  name: 'MyServices',

  components: {
    CustomBox,
    MySubtitle
  },

  data() {
    return {
      dataProjects: [
        { image: 'code', title: 'Criação de sites e Aplicações web' },
        { image: 'gear', title: 'Sustentação e Manutenção' },
        { image: 'mobile', title: 'Responsividade e Componentização' }
      ]
    }
  },

  methods: {
    imagePathFormat(image) {
      return require(`../assets/img/services/${image}.svg`)
    }
  }
}
</script>

<style lang="scss" scoped>
.section {
  width: 100%;
  display: grid;
  justify-items: center;

  &-subtitle {
    margin-bottom: 32px;
  }

  &__boxes {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 32px;

    @include laptop1024 {
      grid-template-columns: repeat(2, 1fr);
    }

    @include tablet768 {
      grid-template-columns: 1fr;
    }

    &-container {
      display: grid;
      gap: 40px;
      padding-top: 16px;

      @include mobile375 {
        margin-left: 16px;
      }

      &-image {
        width: inherit;
        height: inherit;
        text-align: left;
      }

      &-title {
        text-align: left;
        color: $gray-10;
        font-size: 32px;
        word-break: break-word;
        text-align: left;
        font-weight: 600;

        @include mobile375 {
          font-size: 28px;
        }
      }
    }
  }
}
</style>
