<template>
  <div class="box-icon">
    <img
      :src="imagePathFormat"
      :title="name"
      class="icon"
    />
  </div>
</template>

<script>
export default {
  name: 'CustomIcon',

  props: {
    icon: {
      type: String,
      required: true
    },

    name: {
      type: String,
      required: false,
      default: 'icon'
    }
  },

  computed: {
    imagePathFormat() {
      return require(`../../assets/img/about/${this.icon}.svg`)
    }
  }
}
</script>

<style lang="scss" scoped>
.box-icon {
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $dark-20;
  border-radius: 50%;

  .icon {
    width: 32px;
    height: 32px;
    border: none;
  }
}
</style>
