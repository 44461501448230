<template>
  <footer class="footer">
      <p>{{ footerInformations }}</p>
  </footer>
</template>

<script>
export default {
  name: 'MyFooter',

  computed: {
    footerInformations() {
      let curretDay = String(new Date().getDate())
      let currentMonth = String(new Date().getMonth() + 1)
      const currentYear = new Date().getFullYear()

      const heartIcon = `\u2665`
      const copyIcon = '\u00A9'

      if (curretDay.length < 2) {
        curretDay = '0' + curretDay
      }

      if (currentMonth.length < 2) {
        currentMonth = '0' + currentMonth
      }

      return `${copyIcon} ${curretDay}/${currentMonth}/${currentYear} | Feito com ${heartIcon} e muito café!`
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  width: 100vw;
  position: absolute;
  left: 0;

  p {
    display: flex;
    gap: 4px;
    place-content: center;
    padding: 16px 0;
    color: $ligth-20;
    background-color: $dark-20;
    font-size: 16px;

    @include mobile375 {
      font-size: 14px;
    }
  }
}
</style>
